import { Component, OnDestroy, OnInit } from '@angular/core';
import { DocumentService } from '../../../../services/document.service';
import { IPage, IPaginationData } from '../../../../shared/models/pagination-data.model';
import {
  IDocument,
  IDocumentSearchParam
} from '../../../../services/models/document/document.model';
import { IOrderParam } from '../../../../shared/directives/sort/order.directive';
import { AlertService } from '../../../../services/alert.service';
import { CompanyService } from '../../../../services/company.service';
import { faBoxOpen, faDownload, faEdit, faPlus, faSortUp, faTrash } from '@fortawesome/free-solid-svg-icons';
import { environment } from '../../../../../environments/environment';
import { OpenConfirmationModal } from '../../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { DocumentModalComponent } from './document-modal/document-modal.component';
import { FileService } from '../../../../services/file.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit, OnDestroy {
  // Icons
  protected readonly faSortDesc = faSortUp;
  protected readonly faEdit = faEdit;
  protected readonly faDelete = faTrash;
  protected readonly faEmpty = faBoxOpen;
  protected readonly faAdd = faPlus;
  protected readonly faDownloadIcon = faDownload;

  paginationData: IPaginationData<IDocument>;
  searchParam: IDocumentSearchParam;
  page: IPage;
  orderParam: IOrderParam;
  documentCategories$ = this.documentService.documentCategories$.asObservable();
  companies$ = this.companyService.ActiveMembers$.asObservable();

  // General variables
  loading: boolean = false;
  env = environment;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private documentService: DocumentService,
              private companyService: CompanyService,
              private fileService: FileService,
              private modalService: NgbModal,
              private alertService: AlertService) { }

  ngOnInit(): void {
    this.loading = true;
    // Init pagination data
    this.paginationData = {
      DataSet: [],
      Data: null,
      CurrentPage: 1,
      PageSize: 30,
      TotalPages: 1
    };
    // Init page
    this.page = {
      pageNumber: this.paginationData.CurrentPage,
      pageSize: this.paginationData.PageSize
    } as IPage;
    // Set default ordering
    this.orderParam = {
      OrderBy: 'DateCreated',
      OrderDirection: 'desc'
    } as IOrderParam;
    // Load documents
    this.resetSearch();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  resetSearch() {
    this.searchParam = {
      SearchText: null,
      CompanyId: null,
      DocumentCategoryId: null,
      DocumentUserId: null
    };
    this.searchDocuments();
  }

  onPage(page: IPage) {
    this.page = page;
    this.searchDocuments();
  }

  orderSearch(param: IOrderParam) {
    this.orderParam = param;
    this.searchDocuments();
  }

  setCompanyId(id: number) {
    this.searchParam.CompanyId = id;
  }

  setUserId(id: number) {
    this.searchParam.DocumentUserId = id;
  }

  searchDocuments() {
    this.loading = true;

    this.documentService.searchDocuments(this.searchParam, this.page, this.orderParam).subscribe({
      next: (data: IPaginationData<IDocument>) => {
        if (data) {
          this.paginationData = Object.assign({}, data);
          // Populate extensions
          this.paginationData.DataSet.forEach(x => x.Extension = this.getFileType(x.FileName).toLowerCase());
        }
        this.loading = false;
      }, error: () => {
        this.loading = false;
        this.alertService.error('Failed to search documents.');
      }
    });
  }

  getFileType(fileName: string) {
    return fileName.substring(fileName.lastIndexOf('.') + 1);
  }

  downloadDocument(doc: IDocument) {
    this.fileService.GetFile('documents', doc.FileName, doc.DisplayName + doc.Extension);
  }

  editDocument(document: IDocument) {
    // Open modal to Add/Edit a document
    const modalRef = this.modalService.open(DocumentModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.DocumentId = document.Id;
    modalRef.componentInstance.SetDocument();
    // On modal close, read the result and apply logic
    modalRef.result.then((result: IDocument) => {
      if (result) {
        document.DocumentCategoryId = result.DocumentCategoryId;
        document.DocumentCategoryName = result.DocumentCategoryName;
        document.CompanyId = result.CompanyId;
        document.CompanyName = result.CompanyName;
        document.FileName = result.FileName;
        document.DisplayName = result.DisplayName;
        document.Description = result.Description;
        document.DocumentUserId = result.DocumentUserId;
        document.DocumentUserName = result.DocumentUserName;
      }
    }, () => { });
  }

  addDocument() {
    // Open modal to Add/Edit a document
    const modalRef = this.modalService.open(DocumentModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.DocumentId = null;
    modalRef.componentInstance.SetDocument();
    // On modal close, read the result and apply logic
    modalRef.result.then((result: IDocument) => {
      if (result) {
        this.searchDocuments();
      }
    }, () => { });
  }

  deleteDocument(documentId: number) {
    const message = `This document will be deleted. This action can not be undone. \n\n Continue?`;
    OpenConfirmationModal(this.modalService, message)
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap(answer => {
          if (answer) {
            const successMessage = `Document successfully deleted.`;
            return this.documentService.deleteDocument(documentId)
              .pipe(
                tap(() => {
                  this.alertService.success(successMessage);
                  this.searchDocuments();
                }));
          }
        })
      ).subscribe();
  }
}
